import React ,{useState}from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import header from './header.png';
export default function Home() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const showToastMessage = (message, type) => {
    toast(message, {
      position: toast.POSITION.TOP_RIGHT,
      type: type,
    });
  };

  const validationError = () => {
    setEmailError('Enter a valid email.');
    showToastMessage('Enter a valid email.', 'error');
  };

  const validate = () => {
    const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const trimmedEmail = email.trim();

    if (trimmedEmail === '' || !emailRegex.test(trimmedEmail)) {
      validationError();
    } else {
      checkUser();
    }
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
    setEmailError('');
  };

  const save = () => {
    fetch('https://secureaudit.ai/SecureAudit/Users/addusers2.php', {
      method: 'POST',
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success === '1') {
          console.log(json.msg);
          showToastMessage('Hooray! You are on the waiting list!', 'success');
        }
      });
  };
  const checkUser = () => {
    fetch('https://secureaudit.ai/SecureAudit/Users/Checkuser.php', {
      method: 'POST',
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success === '1') {
          console.log(json.msg);
          showToastMessage('Already Registered!', 'error');
        } else if (json.success === '0') {
          save();
        }
      });
  };
  
  return (
   <>
   <div className="container-fluid align-items-center h-100 bg-black">
    <div className="row w-100 justify-content-left mt-3 mb-5">
      <div className="col-md-5 text-left d-flex flex-column flex-sm-row" style={{marginBottom:"80px"}}>
        <img src={header} alt="" style={{height:"90px"}} />
        {/* <h1 className="fw-bold" style={{color:"rgba(172,144,245,255)"}}>Secure Audit</h1> */}
      </div>
    </div>
    <div className="row justify-content-center mt-5 text-center ">
    <div className="col-sm-1 col-md-3 text-center position-relative d-flex align-items-center justify-content-center">
  {/* Background with opacity */}
  <div
    className="position-absolute w-100 h-100"
    style={{
      backgroundColor: "rgba(172, 144, 245, 0.7)",
      borderRadius: "25px",
    }}
  ></div>

  {/* Content with full opacity */}
  <div
    className="position-relative text-white"
    style={{
      zIndex: 1, // Bring the text to the front
      fontSize: "20px",
    }}
  >
    Secure Audit is Coming Soon 🛡️
  </div>
</div>


    </div>
    <div className="row justify-content-center mt-3 mb-3">
        <div className="col-md-8 text-center display-2 fw-bold text-white">
            Join Our <span style={{color:"#ac90f5"}}> Waitlist</span> & Get Notified When We <span style={{color:"rgba(172,144,245,255)"}}>Launch</span> 
        </div>
    </div>
     <div className="row justify-content-center mt-3">
        <div className="col-md-8 text-center  text-white" style={{fontSize:"25px"}}>  Revolutionizing Smart Contract Auditing with Artificial Intelligence</div>
       
    </div>
    <div className="row justify-content-center mt-3 mb-5">
        <div className="col-sm-6 col-md-3 text-center d-flex flex-column flex-sm-row">
        <div className="input-group mb-3"  >
            <input type="text" className="form-control" placeholder="What's Your Email" aria-label="Whats Your Email" aria-describedby="basic-addon2" style={{borderTopLeftRadius:"25px",borderBottomLeftRadius:"25px",border:"2px solid rgba(172,144,245,255)",height:"45px"}} onChange={handleInputChange}/>
            <div className="input-group-append">
                <button className="button"  style={{borderTopRightRadius:"25px",borderBottomRightRadius:"25px" ,backgroundColor:"rgba(172,144,245,255)",color:"white",height:"45px",border:"2px solid rgba(172,144,245,255)"}} onClick={()=>validate()}>Subscribe</button>
            </div>
         </div>
        </div>
    </div>
    <ToastContainer/>
  </div>
   </>
  )
  }
